import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertMessageService } from '@serpro/ngx-dsgovbr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { HEADER_SKIP_ERROR_INTERCEPTOR } from '../error/error.headers';
import { PAUBRASIL_MESSAGES } from '../shared/enum/pau-brasil-messages.enum';
import { ScaUser } from './sca-user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _user!: ScaUser;
  private resourceUrl = environment.serviceAnaliseUrl;

  constructor(
    private http: HttpClient,
    private alertMessageService: AlertMessageService) { }

  redirectToLoginPage() {
    window.location.href = LOGIN_URL;
  }

  isAuthenticated(): boolean {
    return !!this.user;
  }

  login(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isAuthenticated()) {
        resolve();
      } else {
        if (window.location.href.endsWith(LOGIN_SUCCESS_ROUTE)) {
          this.loadUser().toPromise()
            .then(() => resolve())
            .catch(error => reject(error));
        } else {
          this.redirectToLoginPage();
        }
      }
    });
  }

  get user(): ScaUser {
    if (!this._user) {
      const storageUser = sessionStorage.getItem(USER_STORAGE_ID);
      if (storageUser) {
        this._user = Object.freeze(JSON.parse(storageUser)) as ScaUser;
      }
    }

    return this._user;
  }

  hasRole(...roles: string[]) {
    if (!this.user || !this.user.roles || this.user.roles.length === 0) {
      return false;
    }

    return this.user.roles.some(role => roles.indexOf(role) >= 0);
  }

  private loadUser() {
    return this.http.get<ScaUser>(API_USER).pipe(
      tap(user => {
        this._user = user;
        sessionStorage.setItem(USER_STORAGE_ID, JSON.stringify(user));
      })
    );
  }

  get userImg$() {
    const imgStorage = sessionStorage.getItem(USER_IMG_STORAGE_ID);
    if (imgStorage) {
      return of(imgStorage);
    }

    return this.loadUserImg().pipe(
      tap(imgUrl => sessionStorage.setItem(USER_IMG_STORAGE_ID, imgUrl))
    )
  }

  get logout$(): Observable<void> {
    return this.http.get(API_LOGOUT, {
      responseType: 'text'
    }).pipe(
      map(_ => {
        sessionStorage.removeItem(USER_STORAGE_ID);
        sessionStorage.removeItem(USER_IMG_STORAGE_ID);

        window.location.href = LOGOUT_URL;

        return;
      })
    );
  }

  private loadUserImg() {
    return this.http.get(this.resourceUrl + '/sisreg/obterFoto', {
      responseType: 'blob',
      headers: new HttpHeaders().append(HEADER_SKIP_ERROR_INTERCEPTOR, 'true')
      }).pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status !== 404) {
              this.alertMessageService.error(PAUBRASIL_MESSAGES.ERRO_BUSCAR_FOTO);
            }
          }
          return throwError(error)
        }),
        mergeMap(foto => new Observable<string>(
          (subscriber) => {
            const reader = new FileReader();

            reader.onerror = (err) => subscriber.error(err);
            reader.onloadend = () => {
              subscriber.next(reader.result as string);
              subscriber.complete();
            };

            reader.readAsDataURL(foto);
          }
        )
      )
    )
  }

}

const API_USER = '/api/user/details';
const API_LOGOUT = '/api/logout';
const LOGIN_URL = '/login/cas';
const LOGOUT_URL = '/cas/logout';
const USER_STORAGE_ID = 'user';
const USER_IMG_STORAGE_ID = 'user_img'
const LOGIN_SUCCESS_ROUTE = 'login-success';
