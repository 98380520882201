// constante com  mensagens de erros http genéricos
export enum PAUBRASIL_MESSAGES {
    CONFIRMACAO_ALTERCAO_SITUACAO_LPCO = 'Confirmação de alteração situação LPCO - Confirma alteração da situação da LPCO ?',
    LOGIN_SENHA_INVALIDO = 'Login ou senha inválidos.',
    CPF_CNPJ_NAO_ENCONTRADO = 'CPF-CNPJ não encontrado na base do Ibama.',
    NENHUM_REGISTRO_ENCONTRADO = 'Nenhum registro encontrado.',
    DATA_INICIAL_FINAL_INVALIDA = 'A data inicial e a data final devem obedecer a um limite temporal de, no máximo, 5 anos entre elas.',
    OPERACAO_REALIZADA_SUCESSO = 'Operação realizada com sucesso!',
    ERRO_BUSCAR_FOTO = 'Erro ao buscar a foto do usuário (SISREG).',
    LISTA_SITUACOES_NAO_CARREGADA = 'Lista de Situações não foi carregada.',
    LISTA_EXIGENCIAS_NAO_CARREGADA = 'Lista de Exigências não foi carregada.',
    LISTA_TAXAS_NAO_CARREGADA = 'Lista de Taxas dos Modelos não foi carregada.',
    LISTA_TAXAS_POR_MODELO_NAO_CARREGADA = 'Lista de Taxas por Modelo não foi carregada.',
    NAO_EXISTE_TAXA_DEFINIDA = 'Não foi definida taxa para esse modelo.',
    MODELO_ATUALIZADO_COM_SUCESSO = "Atualização de Modelo realizada com sucesso.",
    EXIGENCIA_INCLUIDA_SUCESSO = "Exigência incluída com sucesso.",
    EXIGENCIA_CANCELADA_SUCESSO = "Exigência cancelada com sucesso.",
    LISTA_MODELOS_NAO_CARREGADA = "Lista de Modelos não foi carregada.",
    LISTA_CATEGORIAS_NAO_CARREGADA = "Lista de Categorias não foi carregada",
    LISTA_ATIVIDADES_NAO_CARREGADA = "Lista de Atividades não foi carregada",
    LISTA_PAIS_IMPORTADOR_NAO_CARREGADA = "Lista de País impoortador não foi carregada.",
    LISTA_PAIS_DESTINO_NAO_CARREGADA = "Lista de País destino não foi carregada.",
    LISTA_UNIDADE_RFB_DESPACHO_NAO_CARREGADA = "Lista de Unidade RFB Despacho não carregada.",
    LISTA_UNIDADE_RFB_EMBARQUE_NAO_CARREGADA = "Lista de Unidade RFB Embarque não carregada.",
    LPCO_NAO_POSSUI_ANEXOS = "Não foram encontrados documentos anexos à esta LPCO.",
    ANALISE_NAO_CARREGADA = "Análise não carregada",
    NAO_ENCONTRADAS_EXIGENCIAS_PARA_LPCO = "Não foram encontradas Exigências para esta LPCO",
    NAO_ENCONTRADO_HISTORICO_PARA_LPCO = "Não foi encontrado Histórico para esta LPCO",
    ATRIBUICAO_COM_SUCESSO = "Atribuição realizada com sucesso.",
    ALTERACAO_SITUACAO_LPCO_REALIZADA_SUCESSO = "Alteração da situação da LPCO realizada com sucesso.",
    RETIFICACAO_ACEITA_SUCESSO = "A Solicitação de retificação foi aceita.",
    RETIFICACAO_NEGADA_SUCESSO = "A Solicitação de retificação foi negada.",
    LPCO_NAO_POSSUI_RETIFICACOES = "Não foram encontradas Solicitações de Retificação para esta LPCO.",

    ERRO_CARREGAR_INFORMACOES_CTF = "Erro ao carregar informações de Categoria/Atividade/Modelo de LPCO",
    CTF_MODELO_EXCLUIDO_SUCESSO = "Configuração de Categoria/Atividade CTF e Modelo de LPCO excluída com sucesso.",
    CTF_MODELO_INCLUIDO_SUCESSO = "Configuração de Categoria/Atividade CTF e Modelo de LPCO incluída com sucesso.",

    JUSTIFICATIVA_NAO_INFORMADA = "Justificativa deve ser informada",
    PRORROGACAO_ACEITA_SUCESSO = "A Solicitação de prorrogação de LPCO foi aceita.",
    PRORROGACAO_NEGADA_SUCESSO = "A Solicitação de prorrogação de LPCO foi negada.",
    LPCO_NAO_POSSUI_SOLICITACAO_PRORROGACAO = "Não foram encontradas Solicitações de Prorrogação para esta LPCO.",

    LISTA_EQUIPES_NAO_CARREGADA = 'Lista de Equipes não foi carregada.',
    NEHUM_REGISTRO_ENCONTRADO = "Nenhum registro encontrado.",
    EQUIPE_EXCLUIDA_SUCESSO = "Equipe excluída com sucesso.",
    EQUIPE_INCLUIDA_SUCESSO = "Equipe incluída com sucesso.",
    EQUIPE_ALTERADA_SUCESSO = "Equipe alterada com sucesso.",
    EQUIPE_NAO_ENCONTRADA = "Equipe não encontrada.",
    MEMBRO_INCLUIDO_SUCESSO = "Membro designado para a equipe com sucesso.",
    MEMBRO_EXCLUIDO_SUCESSO = "Membro da Equipe excluído com sucesso.",
    LISTA_UNIDADE_DESPACHO_NAO_CARREGADA = "Lista de Unidade de Despacho não carregada.",
    NAO_EXISTEM_UNIDADES_DESPACHO_DISPONIVEIS = "Não existem Unidades de Despacho disponíveis para inclusão de uma nova equipe.",

    LISTA_LIDERES_NAO_CARREGADA = 'Lista de líderes não carregada.',
    LISTA_MEMBROS_NAO_CARREGADA = 'Lista de membros não carregada.',

    CAMPO_OBRIGATORIO = "É obrigatório informar o(s) campo(s):",
    DATA_FINAL_MENOR_INICIAL = "Data final não pode ser anterior à data inicial.",
    SOLICITACAO_RELATORIO_SUCESSO = 'Solicitação para exportação de relatório gerada com sucesso.',
    ERRO_GENERICO = "Não foi possível completar essa operação."
  }
