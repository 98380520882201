import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DadosIndicadoresBoleto as DadosIndicadoresBoleto } from './models/dados-indicadores-gru.model';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  resourceUrl = environment.serviceAnaliseUrl;

  constructor(private http: HttpClient) { }

  carregarDadosIndicadorSituacao(): Observable<any>  {
    return this.http.get(this.resourceUrl + '/dashboard/situacao');
  }

  carregarDadosIndicadorRetificacao(): Observable<any>  {
    return this.http.get(this.resourceUrl + '/dashboard/retificacao');
  }

  carregarDadosIndicadorProrrogacao(): Observable<any>  {
    return this.http.get(this.resourceUrl + '/dashboard/prorrogacoesPendentes');
  }

  carregarDadosIndicadorBoleto(): Observable<DadosIndicadoresBoleto>  {
    return this.http.get<DadosIndicadoresBoleto>(this.resourceUrl + '/dashboard/boleto');
  }

  carregarQtdLpcosNaoDistribuidasParaEquipe(): Observable<number> {
    return this.http.get<number>(this.resourceUrl + '/dashboard/naoDistribuidasParaEquipe');
  }


}
