import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScaUser } from 'src/app/security/sca-user.model';
import { UserService } from 'src/app/security/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  constructor(private userService: UserService) { }

  @Input()
  showMenu: boolean = false;

  @Output()
  showMenuChange = new EventEmitter<boolean>();

  @Input()
  logoUrl?: string;

  @Input()
  user: ScaUser = this.userService.user;

  userImg$ = this.userService.userImg$;

  private mediaQuery?: MediaQueryList;

  private destroy$ = new Subject<void>();

  innerWidth: any;

  ngOnInit(): void {
    // this.mediaQuery = window.matchMedia('(min-width: 768px)');
    this.mediaQuery = window.matchMedia('(min-width: 1441px)');
    this.innerWidth = window.innerWidth;
    this.mediaQuery.addEventListener('change', this.changeListener);
    if(this.innerWidth <= 1440) {
      this.showMenuChange.emit(false);
    } else {
      this.showMenuChange.emit(true);
    }
  }

  ngOnDestroy() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener('change', this.changeListener);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeListener = () => {
    this.showMenu = false;
    this.showMenuChange.emit(this.showMenu);
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
    this.showMenuChange.emit(this.showMenu);
  }

  logout() {
    this.userService.logout$.pipe(
      takeUntil(this.destroy$)
    ).subscribe();
  }
}
