// constante com  mensagens de erros http genéricos
export const HTTP_MESSAGES: HttpMessages = {
  401: { title: 'Não Autenticado', description: 'Usuário não autenticado. Efetue o login novamente.', type: 'WARN'},
  403: { title: 'Não Autorizado', description: 'Usuário não autorizado a acessar esta funcionalidade.', type: 'WARN'},
  404: { title: 'Não Encontrado', description: 'O recurso requisitado não pode ser encontrado', type: 'WARN'},
  500: { title: 'Erro Desconhecido', description: 'O servidor encontrou uma condição inesperada.', type: 'ERROR'},
};

type MessageType = 'ERROR' | 'WARN';
type HttpMessages = { [key: number]:  {title: string, description: string, type: MessageType}};
