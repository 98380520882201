import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeptBR from '@angular/common/locales/br';
import localeptBRExtra from '@angular/common/locales/extra/br';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Configuracoes necessarias para o date picker funcionar corretamente
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GovBrModule } from './dsgovbr.module';

import { ErrorModule } from './error/error.module';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './pagina-inicial/header/header.component';
import { PaginaInicialComponent } from './pagina-inicial/pagina-inicial.component';
import { SecurityModule } from './security/security.module';
import { UserService } from './security/user.service';
registerLocaleData(localeptBR, localeptBRExtra);

defineLocale('pt-br', ptBrLocale);

@NgModule({
  declarations: [
    AppComponent, PaginaInicialComponent, HeaderComponent, HomeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SecurityModule,
    GovBrModule,
    HttpClientModule,
    ErrorModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (userService: UserService) => () => initializeApp(userService),
      multi: true,
      deps: [UserService]
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

/** Function executada antes da inicialização da aplicação. */
function initializeApp(userService: UserService): Promise<void> {
  return userService.login();
}
