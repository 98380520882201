import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Application, Menu, User } from '@serpro/ngx-dsgovbr';
import { Roles } from '../security/roles.enum';
import { UserService } from '../security/user.service';

@Component({
  selector: 'app-pagina-inicial',
  templateUrl: './pagina-inicial.component.html',
  styleUrls: ['./pagina-inicial.component.css']
})
export class PaginaInicialComponent implements OnInit {

  constructor(private userService: UserService) {}

  showScrollToTop = false;
  @Input() showMenu = true;

  usuario: User = {
    name: this.userService.user.nome,
    displayName: this.userService.user.nome,
    email: this.userService.user.email,
    roles: this.userService.user.roles.map(role => ({
      name: role,
      description: role // TODO: verificar onde o ngx-dsgov usa essa description
    })),
  };

  menu: Menu = {
    itens: [
      {
        label: 'Home',
        action: '/home',
        icon: 'fas fa fa-home',
        roleNames: [Roles.INDICADORES_VISUZALIZAR],
      },
      {
        label: "Listar LPCO's",
        action: '/lpco',
        icon: 'fas fa-solid fa-list',
        roleNames: [Roles.LPCO_VISUALIZAR, Roles.LPCO_ANALISAR],
      },
      {
        label: 'Gestão do Sistema',
        icon: 'fas fa-solid fa-globe',
        roleNames: [Roles.TAXAS_VISUALIZAR, Roles.TAXAS_EDITAR, Roles.CONFIGURACOES_CTF_VISUALIZAR, Roles.CONFIGURACOES_CTF_EDITAR],
        itens: [
          {
            label: 'Parametrizar taxas',
            action: 'parametrizar/taxas',
            icon: 'fas fa-solid fa-barcode',
          },
          {
            label: 'Configurar Atividades CTF',
            action: 'configurar/ctfmodelo',
            icon: 'fas fa-cog',
          },
        ]
      },
      {
        label: 'Gestão de Equipes',
        action: '/equipes',
        icon: 'fas fa-solid fa-users',
        roleNames: [Roles.SERVIDORES_VISUALIZAR, Roles.SERVIDORES_EDITAR, Roles.EQUIPES_VISUALIZAR, Roles.EQUIPES_EDITAR],
      },
      {
        label: "Distribuição de LPCO's",
        action: '/distribuir',
        icon: 'fas fa-project-diagram',
        roleNames: [Roles.DISTRIBUIR_EQUIPE, Roles.DISTRIBUIR_SERVIDOR],
      },
      {
        label: 'Relatórios',
        icon: 'fas fa-solid fa-file',
        roleNames: [Roles.EXPORTAR_RELATORIO],
        itens: [
          {
            label: 'Gerar Dados LPCO\'s',
            action: 'relatorios/gerar',
            icon: 'fas fa-solid fa-file-csv',
          },
          {
            label: 'Exportar Dados LPCO\'s',
            action: 'relatorios/exportar',
            icon: 'fas fa-solid fa-file-export',
          },
        ]
      },
    ]
  };

  app: Application = {
    menu: this.menu,
    version: 'PLATAFORMA DE ANUÊNCIA ÚNICA',
    name: 'PAU-BRASIL',
  };

  ngOnInit() {
    sessionStorage.removeItem('equipeFiltro');
    sessionStorage.removeItem('filtros');
    sessionStorage.removeItem('distribuicaoFiltro');
  }

  rolarParaTopo() {
    window.scroll(0, 0);
  }

  @HostListener('window:scroll')
  onScroll() {
    this.showScrollToTop = window.pageYOffset > 0;
  }

  onMenuContainerClick() {
    sessionStorage.removeItem('equipeFiltro');
    sessionStorage.removeItem('filtros');
    if (this.app.menu.overlay) {
      this.showMenu = false;
    }
  }

}

