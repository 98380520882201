export const environment = {
  production: true,
  serviceAnaliseUrl: '/paubrasilanaliselicenciamento/api',
  serviceGestaoUrl: '/paubrasilgestao/api',
  serviceBuscaUrls: '/paubrasilbuscalicenciamento/api',
  serviceRelatorioUrls: '/paubrasilrelatorio/api',
  apiUrl: '/api',
  errorMessageHeaderParameterName: 'X-paubrasil-error',
  auth: {
    baseUrl: '',
    authUrl: '/login/cas',
    loginUrl: '/login/cas',
    logoutUrl: '/cas/logout',
    detailsUrl: '/api/user/details',
    tokenValidationUrl: '/api/token/validate',
    storage: localStorage,
    tokenStorageIndex: 'token',
    userStorageIndex: 'user',
    userStorage: localStorage
  }
};
