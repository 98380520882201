import {
  HttpErrorResponse, HttpEvent, HttpHandler,

  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertMessageService } from '@serpro/ngx-dsgovbr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HEADER_SKIP_ERROR_INTERCEPTOR } from './error.headers';
import { HTTP_MESSAGES } from './http-error.messages';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private alertMessageService: AlertMessageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const skipErrorInterceptor = request.headers.get(HEADER_SKIP_ERROR_INTERCEPTOR)
      && request.headers.get(HEADER_SKIP_ERROR_INTERCEPTOR) === 'true';

    const requestClone = request.clone({
      headers: request.headers.delete(HEADER_SKIP_ERROR_INTERCEPTOR)
    });

    return next.handle(requestClone).pipe(
      catchError(error => {
        if (!skipErrorInterceptor) {
          if (error instanceof HttpErrorResponse) {
            const httpError = error as HttpErrorResponse;
            this.handleError(httpError);
          } else {
            console.error(error);
          }
        }

        return throwError(error);
      })
    );
  }

  handleError(error: HttpErrorResponse) {
    const message = HTTP_MESSAGES[error.status];
    if (message) {
      if (message.type === 'ERROR') {
        this.alertMessageService.error(message.title, message.description);
        console.error(error);
      } else {
        this.alertMessageService.warn(message.title, message.description);
      }
    } else {
      // TODO: tratar erros negociais retornados pelo backend. 422?

    }
  }
}
