export enum Roles {
  INDICADORES_VISUZALIZAR = 'ROLE_MOD_INDICADORES_FUN_EXIBIR_INDICADORES_VISUALIZAR',
  LPCO_VISUALIZAR = 'ROLE_MOD_LPCO_FUN_MANTER_LPCO_VISUALIZAR',
  LPCO_ANALISAR = 'ROLE_MOD_LPCO_FUN_MANTER_LPCO_ANALISAR',
  TAXAS_VISUALIZAR = 'ROLE_MOD_GESTAO_FUN_MANTER_TAXAS_VISUALIZAR',
  TAXAS_EDITAR = 'ROLE_MOD_GESTAO_FUN_MANTER_TAXAS_EDITAR',
  CONFIGURACOES_CTF_VISUALIZAR = "ROLE_MOD_GESTAO_FUN_MANTER_CONFIG_CTF_VISUALIZAR",
  CONFIGURACOES_CTF_EDITAR = "ROLE_MOD_GESTAO_FUN_MANTER_CONFIG_CTF_EDITAR",
  EQUIPES_VISUALIZAR = 'ROLE_MOD_GESTAO_FUN_MANTER_EQUIPES_VISUALIZAR',
  EQUIPES_EDITAR = 'ROLE_MOD_GESTAO_FUN_MANTER_EQUIPES_EDITAR',
  SERVIDORES_EDITAR = 'ROLE_MOD_GESTAO_FUN_MANTER_SERVIDORES_EDITAR',
  SERVIDORES_VISUALIZAR = 'ROLE_MOD_GESTAO_FUN_MANTER_SERVIDORES_VISUALIZAR',
  DISTRIBUIR_SERVIDOR = 'ROLE_MOD_GESTAO_FUN_DISTRIBUIR_LPCO_DISTRIBUIR_SERVIDOR',
  DISTRIBUIR_EQUIPE = 'ROLE_MOD_GESTAO_FUN_DISTRIBUIR_LPCO_DISTRIBUIR_EQUIPE',
  EXPORTAR_RELATORIO = 'ROLE_MOD_RELATORIO_FUN_GERAR_DADOS_LPCO_EXPORTAR',
}
