import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { AlertMessageService } from '@serpro/ngx-dsgovbr';
import { HTTP_MESSAGES } from '../error/http-error.messages';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private userService: UserService,
    private alertMessageService: AlertMessageService) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.userService.isAuthenticated()) {
      this.userService.redirectToLoginPage();
    }

    // verifica permissão
    const hasRole = !childRoute.data.role || childRoute.data.role.length === 0 || this.userService.hasRole(...childRoute.data.role);
    if (!hasRole) {
      const message403 = HTTP_MESSAGES[403];
      this.alertMessageService.warn(message403.title, message403.description);
    }

    return hasRole;
  }

}
