import { Component, OnDestroy, OnInit } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SituacoesPermitidas } from '../shared/enum/situacoes-permitidas.enum';
import { Pageable } from '../util/pageable-request';
import { HomeService } from './home.service';
import { CardIndicadorSituacao } from './models/cardIndicadorSituacao.models';
import { DadoIndicadorRetificacao } from './models/dadoIndicadorRetificacao.models';
import { DadosIndicadoresBoleto } from './models/dados-indicadores-gru.model';
import { DadosIndicadores } from './models/dados-indicadores.models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  view: boolean = true;

  ColumnMode = ColumnMode;
  offSet: number = 0;
  pageSize: number = 10;
  pageable = new Pageable(0, 10);
  currentPage: number = 1;
  columns: any[] = [];
  totalElementos: number = 0;
  sort: any;

  loadingVisible: boolean = false;
  loadingMensage: string = 'Carregando Indicadores ...';

  public dadoIndicadorRetificacaoLista: DadoIndicadorRetificacao[] = [];
  public retificacoesPendentes: number = 0;
  public prorrogacoesPendentes: number = 0;
  public indicadorSituacaoParaAnalise: CardIndicadorSituacao = new CardIndicadorSituacao();
  public indicadorSituacaoDeferida: CardIndicadorSituacao = new CardIndicadorSituacao();
  public dadosIndicadores: DadosIndicadores | undefined;
  public dadosIndicadoresBoleto?: DadosIndicadoresBoleto;
  public lpcosNaoDistribuidasParaEquipe?: number = 0;

  private destroy$ = new Subject<void>();

  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
    sessionStorage.removeItem('filtros');
    sessionStorage.removeItem('equipeFiltro');
    sessionStorage.removeItem('distribuicaoFiltro');
    this.carregarIndicadores();
    this.carregarCabecalho();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  carregarCabecalho() {
    this.columns = [
      { name: 'Situação', prop: 'descricaoSituacao', headerClass: 'text-center', cellClass: 'text-left', maxWidth: 270},
      { name: 'Quantidade', prop: 'quantidade', headerClass: 'text-center', cellClass: 'text-center', maxWidth: 175},
      { name: 'Percentual', prop: 'percentual', headerClass: 'text-center', cellClass: 'text-right', sortable: false,  maxWidth: 175},
    ];
  }

  onCurrentPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.pageable.page = pageNumber -1;
    this.loadingVisible = false;
    this.carregarDadosIndicadorSituacao();
  }

  onPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    this.currentPage = 1;
    this.pageable.page = 0;
    this.pageable.setSize(this.pageSize);
    this.loadingVisible = false;
    this.carregarDadosIndicadorSituacao();
  }

  onSort(event: { sorts: any[]; }) {
    this.sort = event.sorts[0];
    this.currentPage = 1;
    this.loadingVisible = false;
    this.carregarDadosIndicadorSituacao();
  }

  carregarIndicadores() {
      this.loadingVisible = true;
      this.carregarDadosIndicadorSituacao();
      this.carregarDadosIndicadorRetificacao();
      this.carregarProgrrogacoesPendentes();
      this.carregarDadosIndicadoresBoleto();
      this.carregarQtdLpcosNaoDistribuidasParaEquipe();
      this.loadingVisible = false;

  }

  carregarDadosIndicadorSituacao() {
    this.homeService.carregarDadosIndicadorSituacao().pipe(
      takeUntil(this.destroy$),
    ).subscribe((resultado: any) => {
      this.dadosIndicadores = resultado;
      this.totalElementos = resultado.indicadores.length;

      this.indicadorSituacaoParaAnalise = resultado.indicadores.filter(function(indicador: { codigoSituacao: SituacoesPermitidas; }) {
        return (indicador.codigoSituacao  == SituacoesPermitidas.PARA_ANALISE);
      })[0];

      this.indicadorSituacaoDeferida = resultado.indicadores.filter(function(indicador: { codigoSituacao: SituacoesPermitidas; }) {
        return (indicador.codigoSituacao  == SituacoesPermitidas.DEFERIDO);
      })[0];

      if (this.indicadorSituacaoDeferida == undefined) {
        this.indicadorSituacaoDeferida = new CardIndicadorSituacao();
        this.indicadorSituacaoDeferida.quantidade = 0;
      }

    });
  }

  carregarDadosIndicadorRetificacao() {
    this.homeService.carregarDadosIndicadorRetificacao().pipe(
      takeUntil(this.destroy$),
    ).subscribe((resultado: any) => {

      if (resultado.length > 0) {
        this.dadoIndicadorRetificacaoLista = resultado.filter(function(indicador: { codigo: string; }) {
          return indicador.codigo  == SituacoesPermitidas.PARA_ANALISE;
        });
        if (this.dadoIndicadorRetificacaoLista.length > 0) {
          this.retificacoesPendentes = this.dadoIndicadorRetificacaoLista[0].quantidade!;
        } else {
          this.retificacoesPendentes = 0;
        }
      } else {
        this.retificacoesPendentes = 0;
      }
    });
  }

  carregarProgrrogacoesPendentes() {
    this.homeService.carregarDadosIndicadorProrrogacao().pipe(
      takeUntil(this.destroy$),
    ).subscribe((resultado: any) => {
        this.prorrogacoesPendentes = resultado;
    });
  }

  carregarDadosIndicadoresBoleto() {
    this.homeService.carregarDadosIndicadorBoleto().pipe(
      takeUntil(this.destroy$)
    ).subscribe(dadosIndicadoresBoleto =>
      this.dadosIndicadoresBoleto = dadosIndicadoresBoleto
    );
  }

  carregarQtdLpcosNaoDistribuidasParaEquipe() {
    this.homeService.carregarQtdLpcosNaoDistribuidasParaEquipe().pipe(
      takeUntil(this.destroy$)
    ).subscribe(qtde =>
      this.lpcosNaoDistribuidasParaEquipe = qtde
    );
  }
}
