export enum SituacoesPermitidas{
  PARA_ANALISE = 'PARA_ANALISE',
  EM_ANALISE = "EM_ANALISE",
  EM_EXIGENCIA = "EM_EXIGENCIA",
  RESPOSTA_EXIGENCIA = "RESPOSTA_EXIGENCIA",
  EM_VERIFICACAO = "EM_VERIFICACAO",
  EM_INSPECAO = "EM_INSPECAO",
  DEFERIDO = "DEFERIDO",
  INDEFERIDO = "INDEFERIDO"
}
